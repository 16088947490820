<template>
    <div id="Accounting" class="pl-1 m-0" style="min-height: 100vh; border-left: 4px solid white">
        <div class="row">
            <div class="col-12">
                <p class="manrope-title">
                    Contabilidad 
                </p>
            </div>

            <div class="col-12 col-xl-10 py-3 my-3 py-0 pb-3 pb-lg-0   p-0 m-0">
                <div class="row p-0 m-0  justify-content-end align-items-end">

                    <div class="col-12 my-2 col-md-6 col-lg-2">
                        <li-select @change="filterElements"
                            class="opensans-bold"
                            label="Filtrar por:"
                            variant="secondary"
                            :options="[{id: 'active', label: 'Elementos activos'}, {id: 'inactive', label:'Elementos inactivos'}, {id: 'all', label: 'Todos'}]"
                            v-model="filter"
                            full>
                        </li-select>

                    </div>

                    <div class="col-12 my-2 col-md-6 col-lg-3">
                    
                        <li-select @change="filterElements"
                            label="Vehiculo:"
                            class="pl-1"
                            variant="secondary"
                            :options="vehicle_options"
                            v-model="id_vehicle"
                            full>
                        </li-select>
                    </div>

                    <div class="col-12 my-2 col-md-6 col-lg-1 p-0">
                    
                        <li-select @change="searchWeek"
                            label="Año:"
                            class="pl-1"
                            variant="secondary"
                            :options="year_options"
                            v-model="year"
                            full>
                        </li-select>
                    </div>

                    <div class="col-12 my-2 col-md-6 col-lg-4">
                    
                        <li-select @change="filterElements"
                            label="Semana:"
                            class="pl-1"
                            variant="secondary"
                            :options="weeks_options"
                            :disabled="weeks_options.lenght == 0"
                            v-model="number_week"
                            full>
                        </li-select>
                    </div>

                    <div class="col-12 col-md-6 my-2 col-lg-2 position-relative">
                        <input @keyup.enter="filterElements"
                            class="custom_input bg-dark opensans-bold text-white pl-3"
                            type="text"
                            v-model="search_query"
                            placeholder="Buscar"
                            />
                        <img class="position-absolute"
                            style="width: 25px; height: 25px; right: 2rem; top: 0.5rem"
                            :src="lupa_icon" />
                    </div>
                </div>
                

            </div>

            <div class="col-12" v-if="loading && !fetch_error">
                <li-spinner size="sm"/>
            </div>

            <div class="col-12" v-if="!loading && fetch_error">
                <p>
                    Ha ocurrido un error cargando los datos, intente más tarde.
                </p>
            </div>

            <div class="col-12" v-if="empty_search">
                <p>
                    No existen elementos con esos parametros de búsqueda.
                </p>
            </div>
            
            <div class="col-12 font-table" v-if="!loading && !fetch_error && !empty_search && cashboxes.length > 0">
                <li-table :data="accounting_data" 
                    :allow_headers="accounting_headers"
                    pagination_off>
                    <template v-slot:cost_vehicle_rental="data">
                       ${{ formatAmount(data.item.cost_vehicle_rental, 2) }}
                    </template>
                    <template v-slot:rent_discount="data">
                       ${{ formatAmount(data.item.rent_discount, 2) }}
                    </template>
                    <template v-slot:total_rental_cost="data">
                       ${{ formatAmount(data.item.total_rental_cost, 2) }}
                    </template>
                    <template v-slot:total_incident_cost="data">
                       ${{ formatAmount(data.item.total_incident_cost, 2) }}
                    </template>
                    <template v-slot:application_earnings="data">
                       ${{ formatAmount(data.item.application_earnings, 2) }}
                    </template>
                    <template v-slot:application_retentions="data">
                       ${{ formatAmount(data.item.application_retentions, 2) }}
                    </template>
                    <template v-slot:net_earnings="data">
                        <div class="div-field">
                            <div class="hover-trigger">
                                $ {{ formatAmount(data.item.net_earnings, 2) }}
                            </div>
                            <div class="div-field-hover">
                                Ganancias aplicacion: ${{ formatAmount(data.item.application_earnings, 2) }} <br><br>
                                Retenciones aplicacion: ${{ formatAmount(data.item.application_retentions, 2) }}
                            </div>
                        </div>
                    </template>
                    <template v-slot:gas_voucher="data">
                       ${{ formatAmount(data.item.gas_voucher, 2) }}
                    </template>
                    <template v-slot:cash="data">
                       ${{ formatAmount(data.item.cash, 2) }}
                    </template>
                    <template v-slot:card="data">
                       ${{ formatAmount(data.item.card, 2) }}
                    </template>
                    <template v-slot:balance="data">
                        <div class="div-field">
                            <div class="hover-trigger">
                                ${{ formatAmount(data.item.balance, 2) }}
                            </div>
                            <div class="div-field-hover">
                                <span :class="data.item.balance == 0 ? '' : 
                                    data.item.balance > 0 ? 'text-success' : 'text-danger'">
                                        {{ 
                                            data.item.balance == 0 ? 'Sin adeudos' : 
                                                data.item.balance > 0 ? 
                                                    'Cobrar al conductor' : 
                                                    'Pagar al conductor'
                                        }}
                                </span>
                            </div>
                        </div>
                    </template>
                    <template v-slot:currently_paid="data">
                        ${{ formatAmount(data.item.currently_paid, 2) }}
                    </template>
                    <template v-slot:cashbox_status="data">
                    {{ data.item.cashbox_status == 'started' ? 'Activa' : data.item.cashbox_status == 'closed' ? 'Esperando información' : data.item.cashbox_status == 'balance' ? 'Espera de pagos' : data.item.cashbox_status == 'partially_paid' ? 'Abonada' : data.item.cashbox_status == 'paid' ? 'Pagada' : ''}}
                    </template>
                    <template v-slot:init_date="data">
                        {{ new Date(data.item.init_date).toLocaleDateString() }}
                    </template>
                    <template v-slot:end_date="data">
                        {{ new Date(data.item.end_date).toLocaleDateString() }}
                    </template>
                    <template v-slot:details="data">
                        <button @click.stop="selectRow('details', data)"
                            class="btn btn-outline-info btn-pill p-0 inter-bold"
                            style="width: 90px;"
                            type="button">
                            Detalles
                        </button>
                    </template>
                    <template v-slot:add_payment="data">
                        <button @click.stop="selectRow('add_payment', data)"
                            class="btn btn-outline-primary btn-pill p-0 inter-bold"
                            style="width: 90px;"
                            type="button">
                            Abonar
                        </button>
                    </template>
                    <template v-slot:add_balance="data">
                        <button @click.stop="selectRow('add_balance', data)"
                            class="btn btn-outline-warning btn-pill p-0 inter-bold"
                            style="width: 90px;"
                            type="button">
                            Balance
                        </button>
                    </template>
                </li-table>
            </div>
        </div>

        <!---- Paginator ----->

        <div class="col-12" v-if="!fetch_error && !empty_search && cashboxes.length > 0">
            <div class="row justify-content-between">

                <div class="col-12 col-md-5 col-lg-4 d-flex justify-content-center justify-content-lg-start align-items-center px-0 mt-2 mt-md-0">
                    <span class="mr-3 opensans-bold">
                        Filas:
                    </span>
                    
                    <input @keyup.enter="filterElements"
                        type="number"
                        style="width: 60px;"
                        v-model="rows"
                        :disabled="page > 1"
                        class="form-control bg-secondary text-white border-0"
                        />
                    
                    <li-select @change="filterElements"
                        class="pl-1"
                        variant="secondary"
                        :options="[{id: '1', label: 'Antiguos primero'}, {id: '2', label: 'Recientes primero'}]"
                        v-model="order_asc">
                    </li-select>
                </div>

                <div class="col-12 col-md-6 mt-3 mt-md-0 d-flex justify-content-center justify-content-md-end">

                    <button @click.stop="prev"
                        class="btn btn-pill btn-outline-primary"
                        type="button"
                        :disabled="page <= 1">
                        <img :src="arrow_left" />
                    </button>

                    <input @keyup.enter="filterElements"
                        type="number"
                        style="width: 60px;"
                        v-model="page"
                        class="form-control bg-secondary text-white border-0 mx-4 opensans-bold"
                        />


                    <button @click.stop="next"
                        class="btn btn-pill btn-outline-primary"
                        type="button"
                        :disabled="last_page">
                        <img :src="arrow_left" style="transform: rotateY(180deg);" />
                    </button>
                </div>
            </div>
        </div>

        <!---Resume Table--->
        <div class="col-12 d-flex flex-center my-3" v-if="!loading && cashboxes.length > 0">
            <div class="row" style="width: 500px;">
                <div class="col-9 d-flex justify-content-between bg-secondary opensans-bold py-2" style="border-top-left-radius: 15px;">
                    <span>
                        TOTAL DE RENTAS
                    </span>
                    <span>
                        $
                    </span>
                </div>
                <div class="col-3 d-flex justify-content-end border border-secondary py-2">
                    <span>
                        {{ formatAmount(resume.rent_total, 2)}}
                    </span>
                </div>
                <div class="col-9 d-flex justify-content-between bg-secondary opensans-bold py-2">
                    <span>
                        COMBUSTIBLES
                    </span>
                    <span>
                        $
                    </span>
                </div>
                <div class="col-3 d-flex justify-content-end border border-secondary py-2">
                    <span>
                        {{ formatAmount(resume.gas_voucher_total, 2)}}
                    </span>
                </div>
                <div class="col-9 d-flex justify-content-between bg-secondary opensans-bold py-2">
                    <span>
                        TOTAL DE DESCUENTOS
                    </span>
                    <span>
                       - $
                    </span>
                </div>
                <div class="col-3 d-flex justify-content-end border border-secondary py-2">
                    <span>
                       {{ formatAmount(resume.forgive_debt_total, 2)}}
                    </span>
                </div>
                <div class="col-9 d-flex justify-content-between bg-secondary opensans-bold py-2">
                    <span>
                        TOTAL DE FIANZAS
                    </span>
                    <span>
                        $
                    </span>
                </div>
                <div class="col-3 d-flex justify-content-end border border-secondary py-2">
                    <span>
                        {{ formatAmount(resume.fund_debt_total, 2)}}
                    </span>
                </div>
                <div class="col-9 d-flex justify-content-between bg-secondary opensans-bold py-2">
                    <span>
                        TOTAL DE INGRESOS
                    </span>
                    <span>
                        $
                    </span>
                </div>
                <div class="col-3 d-flex justify-content-end border border-secondary py-2">
                    <span>
                        {{ formatAmount(resume.income_total, 2)}}
                    </span>
                </div>
                <div class="col-9 d-flex justify-content-between bg-secondary opensans-bold py-2">
                    <span>
                        SALDO EN APP
                    </span>
                    <span>
                        $
                    </span>
                </div>
                <div class="col-3 d-flex justify-content-end border border-secondary py-2">
                    <span>
                        {{ formatAmount(resume.all_application_total, 2)}}
                    </span>
                </div>
                <div class="col-9 d-flex justify-content-between bg-secondary opensans-bold py-2">
                    <span>
                        DEVOLUCIONES PENDIENTES
                    </span>
                    <span>
                        - $
                    </span>
                </div>
                <div class="col-3 d-flex justify-content-end border border-secondary py-2">
                    <span>
                        {{ formatAmount(resume.balance_due, 2)}}
                    </span>
                </div>
                <div class="col-9 d-flex justify-content-between bg-secondary opensans-bold py-2" style="border-bottom-left-radius: 15px;">
                    <span>
                        SALDO POR COBRAR
                    </span>
                    <span>
                        $
                    </span>
                </div>
                <div class="col-3 d-flex justify-content-end border border-secondary py-2">
                    <span>
                        {{ formatAmount(resume.balance_receivable, 2)}}
                    </span>
                </div>
                <div class="col-12">
                    <hr class="border border-1 w-100 border-secondary">
                </div>
                <div class="col-9 d-flex justify-content-between">
                    <span>
                        FLUJO NETO
                    </span>
                    <span>
                        $
                    </span>
                </div>
                <div class="col-3 d-flex justify-content-end">
                    <span>
                        {{ formatAmount(resume.net_flow, 2)}}
                    </span>
                </div>
            </div>
        </div>

        <!-- Modal-->
        <li-modal :show_modal="details_modal" :max_width="900" class="px-3">
            <div class="container-fluid">
                <div class="col-12 pt-2">
                    <h3>Datos de la renta</h3>
                    <hr style="border: 1px solid #FFFFFF;" class="m-0">
                </div>

                <div class="row px-4">
                    <div class="col-12 col-md-4 pt-3">
                        <p style="margin: 0px; font-size: 20px;">
                            ID Renta:
                        </p>
                        <span class="pl-3 d-flex align-items-end justify-content-between">
                            {{ cashbox.id_rental }} 
                        </span>
                        <hr style="border: 1px solid #008c9c;" class="m-0">
                    </div>

                    <div class="col-12 col-md-4 pt-3">
                        <p style="margin: 0px; font-size: 20px;">
                            Conductor:
                        </p>
                        <span class="pl-3 d-flex align-items-end justify-content-between">
                            {{ cashbox.driver_name }} 
                        </span>
                        <hr style="border: 1px solid #008c9c;" class="m-0">
                    </div>

                    <div class="col-12 col-md-4 pt-3">
                        <p style="margin: 0px; font-size: 20px;">
                            Placas:
                        </p>
                        <span class="pl-3 d-flex align-items-end justify-content-between">
                            {{ cashbox.plate }} 
                        </span>
                        <hr style="border: 1px solid #008c9c;" class="m-0">
                    </div>

                    <div class="col-12 col-md-4 pt-3">
                        <p style="margin: 0px; font-size: 20px;">
                            Coste de Renta:
                        </p>
                        <span class="pl-3 d-flex align-items-end justify-content-between">
                            $ {{ formatAmount(cashbox.cost_vehicle_rental) }} 
                        </span>
                        <hr style="border: 1px solid #008c9c;" class="m-0">
                    </div>

                    <div class="col-12 col-md-4 pt-3">
                        <p style="margin: 0px; font-size: 20px;">
                            Descuento:
                        </p>
                        <span class="pl-3 d-flex align-items-end justify-content-between">
                            $ {{ formatAmount(cashbox.rent_discount) }} 
                        </span>
                        <hr style="border: 1px solid #008c9c;" class="m-0">
                    </div>

                    <div class="col-12 col-md-4 pt-3">
                        <p style="margin: 0px; font-size: 20px;">
                            Costo Total de Renta:
                        </p>
                        <span class="pl-3 d-flex align-items-end justify-content-between">
                            $ {{ formatAmount(cashbox.total_rental_cost) }} 
                        </span>
                        <hr style="border: 1px solid #008c9c;" class="m-0">
                    </div>

                    <div class="col-12 col-md-4 pt-3">
                        <p style="margin: 0px; font-size: 20px;">
                            Costo Total de Incidentes:
                        </p>
                        <span class="pl-3 d-flex align-items-end justify-content-between">
                            $ {{ formatAmount(cashbox.total_incident_cost) }} 
                        </span>
                        <hr style="border: 1px solid #008c9c;" class="m-0">
                    </div>

                    <div class="col-12 col-md-4 pt-3">
                        <p style="margin: 0px; font-size: 20px;">
                            Fianza Restante:
                        </p>
                        <span class="pl-3 d-flex align-items-end justify-content-between">
                            $ {{ formatAmount(cashbox.fund_debt) }} 
                        </span>
                        <hr style="border: 1px solid #008c9c;" class="m-0">
                    </div>
                </div>

                <div class="col-12 pt-4">
                    <h3>Información de la caja</h3>
                    <hr style="border: 1px solid #FFFFFF;" class="m-0">
                </div>

                <div class="row px-4">
                    <div class="col-12 col-md-4 pt-3">
                        <p style="margin: 0px; font-size: 20px;">
                            Fecha de inicio:
                        </p>
                        <span class="pl-3 d-flex align-items-end justify-content-between">
                            {{ new Date(cashbox.init_date).toLocaleDateString() }} 
                        </span>
                        <hr style="border: 1px solid #008c9c;" class="m-0">
                    </div>

                    <div class="col-12 col-md-4 pt-3">
                        <p style="margin: 0px; font-size: 20px;">
                            Fecha de Termino:
                        </p>
                        <span class="pl-3 d-flex align-items-end justify-content-between">
                            {{ new Date(cashbox.end_date).toLocaleDateString() }} 
                        </span>
                        <hr style="border: 1px solid #008c9c;" class="m-0">
                    </div>

                    <div class="col-12 col-md-4 pt-3">
                        <p style="margin: 0px; font-size: 20px;">
                            Estado:
                        </p>
                        <span class="pl-3 d-flex align-items-end justify-content-between">
                            {{ cashbox.cashbox_status == 'started' ? 'Activa' : cashbox.cashbox_status == 'closed' ? 'Esperando información' : cashbox.cashbox_status == 'balance' ? 'Espera de pagos' : cashbox.cashbox_status == 'partially_paid' ? 'Abonada' : cashbox.cashbox_status == 'paid' ? 'Pagada' : ''}} 
                        </span>
                        <hr style="border: 1px solid #008c9c;" class="m-0">
                    </div>

                    <div class="col-12 col-md-6 pt-3">
                        <p style="margin: 0px; font-size: 20px;">
                            Dias laborados:
                        </p>
                        <span class="pl-3 d-flex align-items-end justify-content-between">
                            {{ cashbox.number_days }} 
                        </span>
                        <hr style="border: 1px solid #008c9c;" class="m-0">
                    </div>

                    <div class="col-12 col-md-6 pt-3">
                        <p style="margin: 0px; font-size: 20px;">
                            Semana:
                        </p>
                        <span class="pl-3 d-flex align-items-end justify-content-between">
                            {{ cashbox.number_week }} 
                        </span>
                        <hr style="border: 1px solid #008c9c;" class="m-0">
                    </div>
                </div>
                    
                <div class="col-12 pt-4">
                    <h3>Información introducida</h3>
                    <hr style="border: 1px solid #FFFFFF;" class="m-0">
                </div>

                <div class="row px-4">
                    <div class="col-12 col-md-6 pt-3">
                        <p style="margin: 0px; font-size: 20px;">
                            Total mostrado en aplicación:
                        </p>
                        <span class="pl-3 d-flex align-items-end justify-content-between">
                            $ {{ formatAmount(cashbox.total_application) }} 
                        </span>
                        <hr style="border: 1px solid #008c9c;" class="m-0">
                    </div>

                    <div class="col-12 col-md-6 pt-3">
                        <p style="margin: 0px; font-size: 20px;">
                            Tarjeta de gasolina:
                        </p>
                        <span class="pl-3 d-flex align-items-end justify-content-between">
                            $ {{ formatAmount(cashbox.gas_voucher) }} 
                        </span>
                        <hr style="border: 1px solid #008c9c;" class="m-0">
                    </div>

                    <div class="col-12 col-md-6 pt-3">
                        <p style="margin: 0px; font-size: 20px;">
                            Fianza abonada:
                        </p>
                        <span class="pl-3 d-flex align-items-end justify-content-between">
                            $ {{ formatAmount(cashbox.forgive_debt) }} 
                        </span>
                        <hr style="border: 1px solid #008c9c;" class="m-0">
                    </div>

                </div>

                <div class="col-12 pt-4">
                    <h3>Balance</h3>
                    <hr style="border: 1px solid #FFFFFF;" class="m-0">
                </div>

                <div class="row px-4">
                    <div class="col-12">
                        <p style="margin: 0px; font-size: 20px;">
                            Balance:
                        </p>
                        <span class="pl-3 d-flex align-items-end justify-content-between">
                            $ {{ formatAmount(cashbox.balance) }} 
                        </span>
                        <hr style="border: 1px solid #008c9c;" class="m-0">
                    </div>
                </div>

                <div class="row mt-2">
                    <div class="col-12">
                        <div class="row bg-secondary text-white py-2 text-center" style="border-top-right-radius: 6px; border-top-left-radius: 6px;">
                            <div class="col-2">
                                #
                            </div>
                            <div class="col-2">
                                Monto
                            </div>
                            <div class="col-3">
                                Tipo
                            </div>
                            <div class="col-3">
                                Pago hacia:
                            </div>
                            <div class="col-2">
                                Fecha de pago
                            </div>
                        </div>
                    </div>
                </div>

                <div class="px-3" style="height: 180px; overflow-y: scroll;">
                    <div class="row text-center" v-for="(payment, index) in cashbox.payments" :key="`payment-${index}`">
                        <div class="col-2 border-primary">
                            {{ index + 1}}
                        </div>
                        <div class="col-2 border-primary">
                            $ {{ formatAmount(payment.amount, 2)}}
                        </div>
                        <div class="col-3 border-primary">
                            {{ payment.type ==  'partial_payment' ? 'Pago Parcial' : 'Pago final'}}
                        </div>
                        <div class="col-3 border-primary">
                            {{ payment.payment_for == 'grupolive' ? 'Grupo Live' : 'Conductor'}}
                        </div>
                        <div class="col-2 border-primary">
                            {{ new Date(payment.creation).toLocaleDateString()}}
                        </div>
                    </div>
                </div>

                <div class="row pt-5">
                    <div class="col-12 d-flex align-items-end justify-content-center">
                        <button @click.stop="details_modal = false"
                            class="btn btn-secondary btn-pill"
                            type="button">
                            Cerrar
                        </button>
                    </div>
                </div>
            </div>
        </li-modal>

        <li-modal :show_modal="balance_modal" :max_width="900" class="px-3">
                <div class="col-12 text-center">
                    <h3>
                        Añadir datos de caja
                    </h3>
                </div>
 
                <div class="col-12 col-md-6 p-2 opensans-bold">
                    <li-input label="Total mostrado en aplicación:"
                        type="number"
                        v-model="total_application"
                        placeholder="Total en aplicación"
                        full>
                    </li-input>

                    <span class="text-danger" v-if="errors.total_application">
                        {{ errors.total_application }}
                    </span>
                </div>
                

                <div class="col-12 col-md-6 p-2 opensans-bold">
                    <li-input label="Tarjeta de gasolina:"
                        type="number"
                        v-model="gas_voucher"
                        placeholder="Pago en efectivo."
                        full>
                    </li-input>

                    <span class="text-danger" v-if="errors.gas_voucher">
                        {{ errors.gas_voucher}}
                    </span>
                </div>

                <div class="col-12 col-md-6 p-2 opensans-bold">
                    <li-input label="Descuento:"
                        type="number"
                        v-model="forgive_debt"
                        placeholder="Descuento"
                        full>
                    </li-input>

                    <span class="text-danger" v-if="errors.forgive_debt">
                        {{ errors.forgive_debt}}
                    </span>
                </div>

                <div class="col-12 d-flex justify-content-end mt-2">
                    <button @click.stop="addBalance"
                        class="btn btn-pill"
                        style="height: 35px; padding: 0 20px; color: black; background-color: white">
                        Aplicar
                    </button>
                        
                    <button @click.stop="balance_modal = false"
                        class="btn btn-pill text-white ml-3"
                        style="height: 40px; padding: 0px 25px; background-color: #343A40;">
                        cerrar
                    </button>
                </div>

        </li-modal>

        <li-modal :show_modal="payment_modal" :max_width="900" class="px-3">
                <div class="col-12 text-center">
                    <h3>
                        Añadir datos de caja
                    </h3>
                </div>

                <div class="col-12 col-md-6 p-2 opensans-bold">
                    <li-select label="Tipo:"
                        class="pl-1"
                        variant="secondary"
                        :options="[{id: 'driver', label: 'De GL a conductor'}, {id: 'grupolive', label: 'De conductor a GL'}]"
                        disabled
                        v-model="payment_for">
                    </li-select>
                </div>

                <div class="col-12 col-md-6 p-2 opensans-bold">
                    <li-input label="Abono:"
                        type="number"
                        v-model="amount"
                        placeholder="Abono"
                        full>
                    </li-input>

                    <span class="text-danger"  v-if="errors.amount">
                        {{ errors.amount}}
                    </span>
                </div>

                <div class="col-12">
                    <hr class="border border-secondary w-100">
                    <span class="text-secondary">
                        Historial de pagos
                    </span>

                    <div class="row bg-secondary text-white py-2 text-center" style="border-top-right-radius: 6px; border-top-left-radius: 6px;">
                        <div class="col-2">
                            #
                        </div>
                        <div class="col-2">
                            Monto
                        </div>
                        <div class="col-3">
                            Tipo
                        </div>
                        <div class="col-3">
                            Pago hacia:
                        </div>
                        <div class="col-2">
                            Fecha de pago
                        </div>
                    </div>
                    
                    <div style="height: 180px; overflow-y: scroll">
                        <div class="row text-center" v-for="(payment, index) in payments" :key="`payment-${index}`">
                            <div class="col-2 border-primary">
                                {{ index + 1}}
                            </div>
                            <div class="col-2 border-primary">
                                $ {{ formatAmount(payment.amount, 2)}}
                            </div>
                            <div class="col-3 border-primary">
                                {{ payment.type ==  'partial_payment' ? 'Pago Parcial' : 'Pago final'}}
                            </div>
                            <div class="col-3 border-primary">
                                {{ payment.payment_for == 'grupolive' ? 'Grupo Live' : 'Conductor'}}
                            </div>
                            <div class="col-2 border-primary">
                                {{ new Date(payment.creation).toLocaleDateString()}}
                            </div>
                        </div>
                    </div>
                </div>
                

                <div class="col-12 d-flex justify-content-end mt-4">
                    <button @click.stop="addPayment"
                        class="btn btn-pill"
                        style="height: 35px; padding: 0 20px; color: black; background-color: white">
                        Aplicar
                    </button>
                        
                    <button @click.stop="payment_modal = false"
                        class="btn btn-pill text-white ml-3"
                        style="height: 40px; padding: 0px 25px; background-color: #343A40;">
                        cerrar
                    </button>
                </div>

        </li-modal>
        

        
    </div>
</template>

<script>

    import lupa_icon from 'ASSETS/icons/lupa_icon.png'
    import arrow_left from 'ASSETS/icons/arrow_left.png'
    import { mapGetters } from 'vuex'

    export default {
        data() {
            return {
                lupa_icon,
                arrow_left,
                loading: true,
                fetch_error: false,
                search_query: null,
                empty_search: false,
                payment_modal: false,
                details_modal: false,
                balance_modal: false,
                accounting_headers: [
                    { key: 'driver_name', label: 'Conductor'},
                    { key: 'plate', label: 'Vehicle'},
                    { key: 'total_rental_cost', label: 'Total renta'},
                    { key: 'total_incident_cost', label: 'Costo incidentes'},
                    { key: 'gas_voucher', label: 'Tarjeta gasolina'},
                    { key: 'fund_debt', label: 'Fianza'},
                    { key: 'forgive_debt', label: 'Descuentos'},
                    { key: 'balance', label: 'Balance'},
                    { key: 'init_date', label: 'F. Inicio'},
                    { key: 'end_date', label: 'F. Termino'},
                    { key: 'cashbox_status', label: 'Estado'},
                    { key: 'details'},
                    { key: 'total_application', label: 'T.Aplicación'},
                    { key: 'add_balance'},
                    { key: 'add_payment'}
                ],
                accounting_data: [],
                vehicle_options: [],
                application_earnings: null,
                application_retentions: null,
                gas_voucher: null,
                total_application: null,
                forgive_debt: null,
                id_vehicle: 0,
                year: 'seleccione',
                number_week: null,
                amount: null,
                payment_for: 'driver',
                errors: {
                    application_retentions: null,
                    application_earnings: null,
                    gas_voucher: null,
                    amount: null
                },
                selected_id: null,
                filter: 'active',
                 //pagination
                page: 1,
                rows: 5,
                order_asc: '2'
            }
        },
        computed: {
            ...mapGetters({
                cashboxes: 'cashbox/getCashboxes',
                cashbox: 'cashbox/getCashbox',
                vehicles: 'unit_administration/getUnits',
                year_options: 'cashbox/getAvailableYears',
                weeks_options: 'cashbox/getAvailableWeeks',
                last_page: 'models/getLastPage',
                total_pages: 'models/getTotalPages',
                payments: 'cashbox/getPayments',
                resume: 'cashbox/getResume'
            })
        },
        methods: {
            //helpers
            clearData() {
                this.card = null;
                this.cash = null;
                this.gas_voucher = null;
                this.application_earnings = null;
                this.application_retentions = null;
                this.delete_modal = false
                this.balance_modal = false
                this.payment_modal = false
                this.selected_id = null;
                this.amount = null
                for(let key in this.errors) {
                    this.errors[key] = null
                }
            },
            async searchWeek() {
                await this.$store.dispatch('cashbox/availableWeeks', this.year)
            },
            async filterElements(){
                let search = ''
                if(this.search_query) {
                    search = this.search_query
                }

                if( this.total_pages !== null && this.total_pages < this.page) {
                    this.page = this.total_pages == 0 ? 1 : this.total_pages
                }

                let order = this.order_asc == '2' ? false : true;
                
                switch(this.filter) {
                    case 'active':
                        await this.$store.dispatch('cashbox/listCashboxes', { datatable: true, page: this.page, rows: this.rows, status: 'active', search: search, order_asc: order, id_vehicle: this.id_vehicle, year: this.year, number_week: this.number_week})
                        break;
                    case 'inactive':
                        await this.$store.dispatch('cashbox/listCashboxes', { datatable: true, page: this.page, rows: this.rows, status: 'inactive', search: search, order_asc: order, id_vehicle: this.id_vehicle, year: this.year, number_week: this.number_week})
                        break;
                    case 'all':
                        await this.$store.dispatch('cashbox/listCashboxes', { datatable: true, page: this.page, rows: this.rows, status: null, search: search, order_asc: order, id_vehicle: this.id_vehicle, year: this.year, number_week: this.number_week})
                        break;
                }

                if(this.cashboxes === null) {
                    this.empty_search = true
                    return
                }

                else {
                    this.empty_search = false
                }
                
                console.log('cashboxes:', this.cashboxes)
                this.accounting_data = this.cashboxes.map( cashbox => {
                    return {
                        ...cashbox,
                        details: '-',
                        add_balance: '-',
                        add_payment: '-'
                    }
                })
            },
            async selectRow(type, data) {
                switch(type) {
                    case 'details':
                        await this.$store.dispatch('cashbox/viewCashbox', {id_cashbox: data.item.id_cashbox});
                        this.details_modal = true
                        return;
                    case 'add_balance':
                        if(data.item.cashbox_status != 'closed' && data.item.cashbox_status != 'balance') {
                            this.$store.commit('setAlert', {
                                open: true,
                                message: 'Solo se puede añadir información a una caja con estatus "Cerrada" o "Balance"',
                                text_color: 'warning'
                            }, { root: true})
                            return
                        }
                        this.selected_id = data.item.id_cashbox
                        this.balance_modal = true;
                        return;
                    case 'add_payment':
                        if(data.item.cashbox_status != 'balance' && data.item.cashbox_status != 'partially_paid') {
                            this.$store.commit('setAlert', {
                                open: true,
                                message: 'Solo se puede añadir pagos a una caja con estatus "Balance" o "Abonada"',
                                text_color: 'warning'
                            }, { root: true})
                            return
                        }
                        await this.$store.dispatch('cashbox/listPayments', data.item.id_cashbox)
                        this.payment_for = data.item.balance > 0 ? 'grupolive' : 'driver'
                        this.selected_id = data.item.id_cashbox
                        this.payment_modal = true;
                        return;
                    default:
                        console.log('No es un caso valido')
                        return
                }
            },
            async addBalance() {
                for(let error in this.errors) {
                    this.errors[error] = null
                }

                let complete = true
                
                
                if(_.isNull(this.gas_voucher)) {
                    this.errors['gas_voucher'] = '*Campo necesario'
                    complete = false;
                }

                if(_.isNull(this.total_application)) {
                    this.errors['total_application'] = '*Campo necesario'
                    complete = false;
                }

                if(_.isNull(this.forgive_debt)) {
                    this.errors['forgive'] = '*Campo necesario'
                    complete = false;
                }


                if(complete) {
                    let data = {
                        body: {
                            total_application: this.formatAmount(this.total_application, 2),
                            gas_voucher: this.formatAmount(this.gas_voucher, 2),
                            forgive_debt: this.formatAmount(this.forgive_debt, 2),
                        },
                        query: {
                            id_cashbox: this.selected_id
                        }
                        
                    }  

                    await this.$store.dispatch('cashbox/addBalance', data);
                    await this.filterElements();
                    this.clearData();
                    //location.reload()
                }

            },
            async addPayment() {
                for(let error in this.errors) {
                    this.errors[error] = null
                }
                let complete = true
                if(_.isNull(this.amount)) {
                    this.errors['ampunt'] = '*Campo necesario';
                    complete = false
                    return
                } 

                if(complete) {
                    let data = {
                        body: {
                            payment_for: this.payment_for,
                            amount: this.formatAmount(this.amount, 2),
                        },
                        query: {
                            id_cashbox: this.selected_id
                        }
                    }

                    await this.$store.dispatch('cashbox/addPayment', data)
                    await this.filterElements()
                    this.clearData()
                    //location.reload()
                }
            },

            //pagination
            prev(){
                this.page--;
                this.filterElements()
            },
            next(){
                console.log('next')
                this.page++;
                this.filterElements()
            }
        },
        async created() {
            
            try {
                // list of cashbox
                await this.$store.dispatch('cashbox/listCashboxes', {
                    datatable: true,
                    page: this.page,
                    rows: this.rows,
                    order_asc: true,
                    status: this.filter,
                })

                this.accounting_data = this.cashboxes.map( cashbox => {
                    return {
                        ...cashbox,
                        add_balance: '-',
                        add_payment: '-',
                        details: '-'
                    }
                })

                //list of vehicles
                await this.$store.dispatch('unit_administration/listVehicles', { datatable: false, status: 'active'})
                this.vehicle_options = this.vehicles.map( vehicle => {
                    return {
                        id: vehicle.id_vehicle,
                        label: `${vehicle.model_name} ${vehicle.plate ? vehicle.plate : 'No hay placas registradas'}`
                    }
                })

                this.vehicle_options = [
                    { id: 0, label: 'Seleccione' },
                    ...this.vehicle_options
                ]

                await this.$store.dispatch('cashbox/availableYears')

                this.loading = false
                this.fetch_error = false

            }
            catch(error) {
                this.loading = false
                this.fetch_error = true
                console.error(error)
            }
        }
    }
</script>

<style lang="scss" scoped>
    #Accounting {
        .custom_input {
            height: 40px;
            border: 1px solid white;
            border-radius: 30px;
            width: 100%;
            &::placeholder{
                padding-left: 15px;
                color: white;
            }

            &:focus{
                outline: none;
            }
        }
    }

    input::-webkit-outer-spin-button,
        input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
        }

    input[type=number] { 
        -moz-appearance: textfield;
        appearance: textfield;
        margin: 0; 

    }
</style>